import React, { useState, useEffect } from "react";
import { Modal, Button, Spin, message as antdMessage } from "antd";
import { bookTable, fetchTableAvailability } from "../modules/LandingPage";
import moment from "moment";

const DynamicTableAvailabilityPopup = ({
  visible,
  onClose,
  bookingData,
  onBack,
  onSubmit,
  bookingType
}) => {
  const [selectedTables, setSelectedTables] = useState([]);
  const [tableData, setTableData] = useState(null); // Initialized to null
  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("selectedCustomer"));
    setCustomerData(user);
  }, []);
  // Fetch the table data from API when component mounts or visibility changes
  useEffect(() => {
    if (visible) {
      const fetchData = async () => {
        setLoading(true);
        try {
          console.log(bookingData);
          const costCenter = localStorage.getItem("costCenter");
          const data = await fetchTableAvailability(
            costCenter,
            bookingData?.selectedDate?.format("DD-MM-YYYY")
          );
          console.log(data);

          // Assuming the structure of the response includes a "message" field
          setTableData(data.data.message); // Set tableData to "message"
        } catch (error) {
          antdMessage.error("Failed to load table data.");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [visible]);

  // Handle table selection
  const handleTableSelect = (id) => {
    setSelectedTables((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((table) => table !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  // Handle "Book" button click
  const handleBookClick = async () => {
    // Prepare payload
    try {
      console.log("bookingData",bookingData);
      const payload = {
        cost_center: localStorage.getItem("costCenter"),
        customer: customerData.name, // Assuming customer_id is stored in customerData
        booking_date: bookingData.selectedDate.format("YYYY-MM-DD"),
        booking_time: moment(bookingData.selectedTime, "HH:mm").format(
          "HH:mm:ss"
        ),
        no_of_guest: bookingData.numGuests,
        special_request: bookingData.specialRequest,
        booked_tables: selectedTables.map((tableNo) => ({ table_no: tableNo })),
      };
      if (bookingType === "DINE IN") {
        payload.booking_type = "Dine In"; 
      }
      
      setLoading(true);
      // Make the API call to book the tables
      const response = await bookTable(payload);
      // Call onSubmit to pass the selected tables back to the parent
      onSubmit(selectedTables, response);
      
    } catch (error) {
      console.log(error);
      antdMessage.error("Failed to book the tables.");
    }finally{
      setLoading(false);
    }
  };

  // Render tables dynamically based on the API response
  const renderTables = (category) =>

    category.map((table) => (
      <div
        key={table.name}
        className={`table-item ${
          table.status === "Available" ? "available" : "unavailable"
        } ${selectedTables.includes(table.name.toString()) ? "selected" : ""}`}
        onClick={() =>
          table.status === "Available" &&
          handleTableSelect(table.name.toString())
        }
      >
        {console.log("table",table)}
        {table.serial_number}
      </div>
    ));

  return (
    <Modal visible={visible} onCancel={onClose} footer={null} centered>
      <div className="table-availability-heading">TABLE AVAILABILITY</div>
      {loading ? (
        <Spin tip="Loading table data..." />
      ) : (
        <div className="table-container">
          {tableData && Object.keys(tableData).length > 0 ? (
            Object.keys(tableData).map((capacity) => (
              <div key={capacity} className="table-category">
                <div className="table-heading">{capacity} Seater Tables</div>
                <div className="table-grid">
                  {renderTables(tableData[capacity])}
                </div>
              </div>
            ))
          ) : (
            <p>No tables available</p>
          )}
        </div>
      )}
      <div className="table-availability-popup-footer">
        <Button className="table-availability-back" onClick={onBack}>
          Back
        </Button>
        <Button
          className="table-availability-book"
          onClick={handleBookClick}
          disabled={selectedTables.length === 0|| loading} // Disable if no table is selected
        >
          Book
        </Button>
      </div>
    </Modal>
  );
};

export default DynamicTableAvailabilityPopup;
