import React from 'react';
import LoginScreen from '../components/LoginScreen';

const LoginPage = () => {
  return (
    <div>
      <LoginScreen />
    </div>
  );
};

export default LoginPage;