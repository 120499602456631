import React, { useState, useEffect } from "react";
import { Modal, Button, Input, message } from "antd";
import moment from "moment";

const DineInPopup = ({ visible, onClose, onSubmit }) => {
  const [selectedDate] = useState(moment());
  const [selectedTime, setSelectedTime] = useState(
    moment().startOf("hour").format("HH:00")
  );
  const [numGuests, setNumGuests] = useState("");
  const [specialRequest, setSpecialRequest] = useState("");
  const [loading, setLoading] = useState(false); // State to manage loading

  useEffect(() => {
    const now = moment();
    const roundedTime = now.startOf("hour").format("HH:00");
    setSelectedTime(roundedTime);
  }, []);

  const handleNumGuestsChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && Number(value) > 0) {
      if (Number(value) <= 100) {
        setNumGuests(value);
      } else {
        message.error("The maximum number of guests allowed is 100.");
      }
    } else if (value === "") {
      setNumGuests("");
    } else {
      message.error("Please enter a valid number of guests.");
    }
  };

  const handleSpecialRequestChange = (e) => {
    const value = e.target.value;
    if (value.length <= 50) {
      setSpecialRequest(value);
    } else {
      message.warning("Special requests are limited to 50 characters.");
    }
  };

  const handleSubmit = async () => {
    if (!numGuests) {
      message.error("Please enter the number of guests.");
      return;
    }

    setLoading(true); // Set loading to true before API call
    try {
      await onSubmit(selectedDate, selectedTime, numGuests, specialRequest);
      onClose(); // Close modal on success
    } catch (error) {
      message.error("Failed to make a reservation. Please try again."); // Error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onClose}
      className="dinein-popup"
      centered
    >
      <h2 className="dineIn-popup-title">DINE IN</h2>
      <div className="dineIn-popup-content">
        <Input
          placeholder="No Of Guests"
          value={numGuests}
          onChange={handleNumGuestsChange}
          className="guests-input"
        />
        <Input
          placeholder="Special Request (Optional)"
          value={specialRequest}
          onChange={handleSpecialRequestChange}
          className="special-request-input"
        />

        <div className="dineIn-popup-footer">
          <Button className="dineIn-back-btn" onClick={onClose}>
            Back
          </Button>
          <Button
            className="dineIn-continue-btn"
            onClick={handleSubmit}
            loading={loading} // Show loader if loading
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DineInPopup;
