import React, { useState , useEffect,useContext} from "react";
import NoImage from "../assets/images/no-img.png";

import { Modal } from "antd";
import { useThemeSettings } from "./ThemeSettingContext";
import { fetchItemAttributes , fetchItemComboItems } from "../modules/LandingPage";
import { CartContext } from "../common/CartContext";
const ProductCard = ({ product, onAddToCart,setSelectedItem, onShowAttributesPopup,onShowComboItemsPopup }) => {
  const costCenter=localStorage.getItem('costCenter')
  const formatPrice = (price) => {
    return price ? price.toFixed(2) : "NA";
  };
  
  const [scannedValue, setScannedValue] = useState("");
  const themeSettings = useThemeSettings();
  const { selectedTab } = useContext(CartContext);
  useEffect(() => {
    const fetchAndStoreAttributes = async () => {
      if (product && product.attributes && product.attributes.length > 0) {
        try {
          const fetchedAttributes = await fetchItemAttributes(product.id);
          console.log("fetchedAttributes",fetchedAttributes)
          localStorage.removeItem(`attributes_${product.id}`); // Remove existing key
          localStorage.setItem(
            `attributes_${product.id}`,
            JSON.stringify(fetchedAttributes)
          );
        } catch (error) {
          console.error("Error fetching attributes:", error);
        }
      }
    };

    const fetchAndStoreComboItems = async () => {
      if (product && product.combo_items && product.combo_items.length > 0) {
        try {
          const fetchedComboItems = await fetchItemComboItems(product.id);
          localStorage.removeItem(`combo_items_${product.id}`); // Remove existing key
          localStorage.setItem(
            `combo_items_${product.id}`,
            JSON.stringify(fetchedComboItems)
          );
        } catch (error) {
          console.error("Error fetching combo items:", error);
        }
      }
    };

    if (product && costCenter) {
      fetchAndStoreAttributes();
      fetchAndStoreComboItems();
    }
  }, [product, costCenter]);
  const getSelectedCustomer = () => {
    const customer = localStorage.getItem("selectedCustomer");
    return customer ? JSON.parse(customer) : null;
  };
  const handleAddToCart = (event) => {
    if(selectedTab==='Booking'){
      Modal.error({
        title: "Attention!",
        content: "Adding products during the booking process is not permitted.",
      });
      return;
    }
    const selectedCustomer = getSelectedCustomer();
    if (!selectedCustomer) {
      Modal.error({
        title: "Attention!",
        content: "Please select a customer before adding items to the cart.",
      });
      return;
    }
      setSelectedItem(product)
    if (product.attributes && product.attributes.length > 0) {
      const selectedAttributes = localStorage.getItem(
        `selectedAttributes_${product.id}`
      );
      onShowAttributesPopup(
        product,
        selectedAttributes ? JSON.parse(selectedAttributes) : null
      );
    } else if (product.combo_items && product.combo_items.length > 0) {
      const selectedComboItems = localStorage.getItem(
        `selectedComboItems_${product.id}_${product.length}`
      );
      onShowComboItemsPopup(
        product,
        selectedComboItems ? JSON.parse(selectedComboItems) : null
      );
    } else {
      onAddToCart(product);
    }
  };
  return (
    <div
      className={`product-card `}
      // className={`product-card ${
      //   product?.stock?.some((qty) => qty?.stock_qty === 0) ? "disabled" : ""
      // }`}
    >
      <div className="product-image">
        <img
          src={product?.image ? product?.image : NoImage}
          alt={product?.name}
        />
      </div>
      {console.log("selle",selectedTab)}
      <div className="product-details">
        <span className="product-type mb-4">{product?.item_type}</span>
        <h4 className="product-name">{product?.name}</h4>
        <span className="product-qty">
          {/* {product?.stock?.map((qty) => qty?.stock_qty)} */}
        </span>
        <div className="price-addbtn">
          <span className="product-price">
            {themeSettings?.currency_symbol || "$"}
            {formatPrice(product?.product_price) || "NA"}
          </span>
          <button className="add-button" onClick={() =>
            {handleAddToCart(product)}}>
            +Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
