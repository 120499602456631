import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({
  element: Component,
  requiredKeys,
  redirectPath = "/",
  ...rest
}) => {
 
  // Function to check if all required keys are in localStorage
  const checkLocalStorageKeys = (keys) => {
    return keys.every((key) => localStorage.getItem(key)); // Ensure all required keys are in localStorage
  };

  // Check if required localStorage data exists and if the currentUser matches the localStorage user
  const hasRequiredData =
    checkLocalStorageKeys(requiredKeys) 

  return hasRequiredData ? (
    <Component {...rest} />
  ) : (
    <Navigate to={redirectPath} />
  );
};

export default PrivateRoute;
